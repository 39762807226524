import React from 'react'
import { Link } from 'gatsby'
import Layout from '@/components/layout'
import Seo from '@/components/seo'
import Breadcrumb from '@/components/breadcrumb'
import PageTitle from '@/components/pageTitle'
import { StaticImage } from 'gatsby-plugin-image'
import nfImg from '@/images/img_404nf.png'

export default function NotFound() {
  return (
    <Layout search={true}>
      <Seo title="お探しのページは存在しません" />
      <div>
        <Breadcrumb items={[{ title: '404 Not Found' }]} />
        <PageTitle title="404 Not Found" />
        <div className="notfound_contents">
          <h2 className="notfound_ttl">お探しのページは見つかりませんでした</h2>
          <p className="notfound_txt">
            URLが間違っているか、ページが存在しません。
          </p>
          <div className="notfound_link">
            <Link className="notfound_link_btn" to="/">
              トップページへ戻る
            </Link>
          </div>
          <div className="notfound_img">
            <StaticImage
              placeholder="none"
              alt="404"
              src="../images/img_404nf.png"
              layout="fullWidth"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
